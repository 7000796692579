import Vue from 'vue'
import Router from 'vue-router'
import realName from '@/components/enter'
import sm from '@/components/sm'
import unicom from '@/components/unicom'
import dsh from '@/components/wait'
import success from '@/components/success'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'enter',
            component: realName
        },
        {
            path: '/sm',
            name: 'enter',
            component: sm
        },
        {
            path: '/dsh',
            name: 'enter',
            component: dsh
        },{
            path: '/unicom',
            name: 'enter',
            component: unicom
        },
        {
            path: '/success',
            name: 'enter',
            component: success
        }
    ]
})
