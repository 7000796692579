<template>
    <div>
        <div class="out-div">
            <div class="sm-title" v-if="outShow">
                <div class="title-left">
                    <i class="el-icon-arrow-left " style="margin-left: 10px;"></i>
                    实名认证
                </div>


            </div>
            <!--        <el-input v-model="cardNumber" placeholder="请输入卡号/20位ICCID" style="width: 80%;"></el-input>-->
            <!--  <div class="top-div">
                  <span style="font-size: large;color: white">账号实名</span>
                  <div style="font-size: xx-large;margin-top: 50px;color: white">18633533</div>
                  <div class="sm-status">实名状态:未实名</div>
              </div>-->
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="sm-form">

                <div class="sfz-div" style="height: auto" v-if="dictStatus=='C_REFUSE'&&outShow">
                    <el-form-item label="实名状态：" prop="name" style="margin-bottom: 0px">
                        <el-input v-model="dictStatusName" class="sm-input" style="color:red !important;"></el-input>
                    </el-form-item>
                    <div class="remark">
                        *{{ remark }}
                    </div>
                </div>

                <div class="sfz-div" v-if="configJson.IDENTITY_PIC_FRONT">

                    <img src="@/assets/sfz_zm.png" class="sfz-sl"/>
                    <el-upload
                            class="avatar-uploader"
                            :action="upload_qiniu_url"
                            :show-file-list="false"
                            :data="qiniuData"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleError" :before-upload="beforeFrontUpload"
                            ref="file" accept="image/*" drag @change="uptype=1">
                        <img :src="frontImageUrl" class="avatar" v-if="frontImageUrl!=''">
                        <i v-if="frontImageUrl==''" class="el-icon-plus avatar-uploader-icon"></i>

                        <div v-if="frontImageUrl==''" class="sfz-font">请上传身份证正面带姓名面</div>
                    </el-upload>
                </div>

                <div class="sfz-div" v-if="configJson.IDENTITY_PIC_BACK">

                    <img src="@/assets/sfz_fm.png" class="sfz-sl"/>
                    <el-upload
                            class="avatar-uploader"
                            :action="upload_qiniu_url"
                            :show-file-list="false"
                            :data="qiniuData"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleError"
                            :before-upload="beforeBackUpload"
                            ref="file" accept="image/*" drag @change="uptype=2">
                        <img :src="backImageUrl" class="avatar" v-if="backImageUrl!=''">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div v-if="backImageUrl==''" class="sfz-font">请上传身份证反面带国徽面</div>
                    </el-upload>
                </div>

                <div class="sfz-div" v-if="configJson.IDENTITY_PIC_IN_HAND">

                    <img src="@/assets/sfz_shouchi.png" style="width: 25%;margin-left: 20px" class="sfz-sl"/>
                    <el-upload
                            class="avatar-uploader"
                            :action="upload_qiniu_url"
                            :show-file-list="false"
                            :data="qiniuData"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleError"
                            :before-upload="beforeHandUpload"
                            ref="file" accept="image/*" drag @change="uptype=3">
                        <!-- <img  src="@/assets/sfz_zm.png" class="avatar"> v-else-->
                        <img :src="handImageUrl" class="avatar" v-if="handImageUrl!=''">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="sfz-font">请上传手持正面身份证</div>
                    </el-upload>
                </div>
                <el-form-item label="业务号码：" prop="name" style="margin-bottom: 0px" v-if="outShow">
                    <el-input v-model="cardNumber" class="sm-input"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名：" v-if="configJson.CUSTOMER_NAME" prop="name" style="margin-bottom: 0px">
                    <el-input v-model="form.customerName" class="sm-input" placeholder="请输入真实姓名"></el-input>
                </el-form-item>
                <el-form-item label="身份证号：" v-if="configJson.ID_WORDS" prop="name">
                    <el-input v-model="form.idWords" class="sm-input" placeholder="请输入18位二代身份证号"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" v-if="configJson.PHONE_NUMBER" prop="name" style="margin-bottom: 0px">
                    <el-input v-model="form.phone" class="sm-input" placeholder="请输入11位手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码：" v-if="configJson.PHONE_NUMBER" prop="name">
                    <el-input v-model="form.msgCode" class="vali-code-input" placeholder="6位验证码"></el-input>
                    <el-button type="primary" class="send-button" :loading="sendCodeLoading" @click="sendButtonClick">
                        {{ sendButtonContent }}
                    </el-button>
                </el-form-item>
                <el-button v-if="displayButton" type="primary" @click="submit" style="width: 80%">确定
                </el-button>
            </el-form>

        </div>

    </div>
</template>

<script>
import {
    uploadRealNameExaminePicture,
    initializationRegister, sendVerificationCode,
    idCardTest, saveRealname, initializeRealName
} from '@/api/getData'
import {encryptDes, decryptDes} from '@/utils/utils'


export default {
    name: "sm",
    data() {
        return {
            outShow: false,
            imageUrl: '',
            cardNumber: "",
            sendCodeLoading: false,
            upload_qiniu_url: 'http://up.qiniup.com',
            sendButtonContent: "发送验证码",
            uptype: 0,
            qiniuData: {},
            frontImageUrl: '',
            upload_qiniu_addr: 'https://sdimg.smerp.com.cn/',
            backImageUrl: '',
            handImageUrl: '',
            encryptedString: '',
            dictStatusName: '未通过',
            remark: "",
            dictStatus: '',
            displayButton: false,
            form: {
                customerName: '',
                idWords: "",
                newEncryptedString: '',
                msgCode: '',
                phone: '',
                uid: '',
                cardId: ''
            },
            configJson: {
                CUSTOMER_NAME: false,
                PHONE_NUMBER: false,
                IDENTITY_PIC_FRONT: false,
                IDENTITY_PIC_BACK: false,
                IDENTITY_PIC_IN_HAND: false,
                ID_WORDS: false,
            },
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            redir: ''
        }
    },
    async created() {
        let cardNumber = this.$route.query.cardNumber
        let cardId = this.$route.query.cardId
        if (cardNumber == undefined || cardNumber == '' || cardNumber.length <= 3) {
            this.$router.push({path: "/"})
        }
        if (cardId == undefined || cardId == '') {
            this.$router.push({path: "/"})
        }
        this.form.cardId = cardId
        this.cardNumber = cardNumber

        if (this.$route.query._redir != undefined) {
            let redir = window.atob(this.$route.query._redir)
            console.log(redir)
            this.redir = redir
            localStorage.setItem("reffer", redir)
        }
        //如果存在发送短信就获取pagekey
        //this.initMessage()
        let back = await initializeRealName({id: cardId})
        if (back._result) {

            //如果实名是自己家
            let obj = back.data

            if (obj.realNameCheckId == 1) {

                if (obj.realnameExamine != null) {
                    //如果是认证成功
                    if (obj.realnameExamine.dictStatus == "C_THROUGH" || obj.realnameExamine.dictStatus == "AUTOMATIC_PASS") {
                        this.$router.push({path: "/success"})
                        return
                    }//如果是正在审核
                    else if (obj.realnameExamine.dictStatus == "C_WAIT") {
                        this.$router.push({path: "/dsh"})
                        return
                    }

                    this.frontImageUrl = obj.realnameExamine.dentityPicFront
                    this.backImageUrl = obj.realnameExamine.dentityPicBack
                    this.handImageUrl = obj.realnameExamine.dentityPicHand
                    //备注
                    this.remark = obj.realnameExamine.remark
                    this.form.phone = obj.realnameExamine.phoneNumber
                    this.dictStatus = obj.realnameExamine.dictStatus
                    //身份证号
                    this.form.idWords = obj.realnameExamine.idWords
                    //真实姓名
                    this.form.customerName = obj.realnameExamine.customerName
                }


                let cfg = JSON.parse(obj.config)
                for (let i in cfg) {
                    if (cfg[i]) {
                        this.displayButton = true;
                    }
                }
                this.configJson.CUSTOMER_NAME = cfg.dictCustomerName
                this.configJson.PHONE_NUMBER = cfg.dictPhoneNumber
                this.configJson.ID_WORDS = cfg.dictIdentityNumber
                this.configJson.IDENTITY_PIC_FRONT = cfg.dictIdentityPicFront
                this.configJson.IDENTITY_PIC_BACK = cfg.dictIdentityPicBack
                this.configJson.IDENTITY_PIC_IN_HAND = cfg.dictIdentityPicInHand

                console.log(this.configJson)
                //如果有短信验证就进行初始化发送短信
                if (this.configJson.PHONE_NUMBER) {
                    console.log("none")
                }
                this.outShow = true

            } else {
                //let iccid = obj.iccid
                let jsRun = obj.jsCode;
                console.log(jsRun);
                if (this.redir != null && this.redir != '') {
                    jsRun = jsRun.replace('{upHttpsUrl}', this.redir)
                    jsRun = jsRun.replace('{upUrl}', this.redir.replace("https://", ''))
                }
                eval(jsRun)
            }

        }


    },
    methods: {
        async intiData() {

        },
        async sendButtonClick() {
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.form.phone))) {
                this.$message.warning("您输入的手机号有误！")
                return
            }
            let sec = 60;
            this.sendCodeLoading = true
            this.sendButtonContent = "" + sec + "秒重试"
            let si = setInterval(() => {
                    sec--
                    this.sendButtonContent = sec + "秒重试"
                    if (sec == 0) {
                        this.sendCodeLoading = false
                        this.sendButtonContent = "发送验证码"
                        window.clearInterval(si)
                    }
                },
                1000)
            const encs = await initializationRegister({
                messageFlag: 'XIAOXIANG'
            })
            console.log(encs)
            if (encs._result) {
                this.encryptedString = encs.data
                console.log(decryptDes(encs.data, 'shendawl', ['s', 'h', 'e', 'n', 'd', 'a', 'w', 'l']))
                const returnData = await sendVerificationCode({
                    uid: decryptDes(encs.data, 'shendawl', ['s', 'h', 'e', 'n', 'd', 'a', 'w', 'l']),
                    phone: this.form.phone,
                    messageFlag: 'XIAOXIANG',
                    remark: "实名"
                })
                if (returnData._result) {
                    this.form.uid = returnData.data
                }

            }


        },
        handleAvatarSuccess: function (res, file) {
            const url = this.upload_qiniu_addr + res.key
            if (this.uptype === 1) {
                this.frontImageUrl = url
                //异步调用接口 去查询人名
                //加密url
                console.log(this.frontImageUrl)
                let a = encryptDes(this.frontImageUrl, "shendawl")
                idCardTest({imgUrl: a}).then(back => {
                    this.form.customerName = back.data.nameWords
                    this.form.idWords = back.data.idWords

                })
                console.log(a);
            }
            if (this.uptype === 2) {
                this.backImageUrl = url
            }
            if (this.uptype === 3) {
                this.handImageUrl = url
            }
            //console.log(this.imageUrl)
        },
        checkFile(file) {
            const isJEPG = file.type === 'image/jpeg'
            const isPNG = file.type === 'image/png'
            const isJPG = file.type === 'image/jpg'
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isJEPG && !isPNG && !isJPG) {
                this.$message.error('图片只能是 JPG/PNG 格式!')
                return false
            }
            if (!isLt2M) {
                this.$message.error('图片大小不能超过 2MB!')
                return false
            }
            return true
        },
        async beforeFrontUpload(file) {

            // this.qiniuData.key = file.name;
            if (!this.checkFile(file)) {
                return false
            }
            const tk = await uploadRealNameExaminePicture({uploadType: 1})
            this.qiniuData = tk.data
            this.uptype = 1
        },
        async beforeBackUpload(file) {
            // this.qiniuData.key = file.name;
            if (!this.checkFile(file)) {
                return false
            }
            const tk = await uploadRealNameExaminePicture({uploadType: 2})
            this.qiniuData = tk.data
            this.uptype = 2
        },
        async beforeHandUpload(file) {
            // this.qiniuData.key = file.name;
            if (!this.checkFile(file)) {
                return false
            }
            const tk = await uploadRealNameExaminePicture({uploadType: 3})
            this.qiniuData = tk.data
            this.uptype = 3
        },

        async initMessage() {


        },
        async submit() {
            /****各种校验*************** 开始*/


            //身份证正面照片
            if (this.configJson.IDENTITY_PIC_FRONT
                && this.frontImageUrl == '') {
                this.$message.warning("请上传身份证正面照片！")
                return
            }

            //身份证反面照片
            if (this.configJson.IDENTITY_PIC_BACK
                && this.backImageUrl == '') {
                this.$message.warning("请上传身份证反面照片！")
                return
            }

            //身份证手持照片
            if (this.configJson.IDENTITY_PIC_IN_HAND
                && this.handImageUrl == '') {
                this.$message.warning("请上传身份证手持照片！")
                return
            }

            if (this.configJson.CUSTOMER_NAME) {
                if (this.form.customerName == '') {
                    this.$message.warning("姓名不能为空")
                    return
                }
                if (!(2 <= this.form.customerName.length &&
                    this.form.customerName.length <= 10)) {
                    this.$message.warning("姓名的长度为6-10个字符")
                    return
                }


            }

            //手机号 验证码判断
            if (this.configJson.PHONE_NUMBER) {
                if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.form.phone))) {
                    this.$message.warning("您输入的手机号有误！")
                    return
                }

                if (this.form.msgCode == '') {
                    this.$message.warning("请输入验证码！")
                    return
                }

                if (!/^\d{6}$/.test(this.form.msgCode)) {
                    this.$message.warning("验证码请输入6位正确的数字！")
                    return
                }
            }

            //判断身份证号
            if (this.configJson.ID_WORDS) {
                let idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
                if (!idcard_patter.test(this.form.idWords)) {
                    this.$message.warning("请输入正确的身份证号！")
                    return
                }
            }


            /****各种校验*************** 结束*/
            this.form.phoneNumber = this.form.phone
            this.form.dentityPicFront = this.frontImageUrl
            this.form.dentityPicBack = this.backImageUrl
            this.form.dentityPicHand = this.handImageUrl

            let a = await saveRealname(this.form);
            if (a._result) {
                this.$router.push({path: "/dsh"})
            }

        },
        handleError() {
            this.$message({
                message: '上传失败',
                duration: 2000,
                type: 'warning'
            })
        }

    }
}
</script>

<style scoped lang="scss">
$sm-width: 232px;

.out-div {
  min-height: 100vh;
  // background: linear-gradient(to bottom, #007fff 80%, #00b2ff 100%);;
  background-color: #f6f6f7;
  height: auto;
  padding-bottom: 10px;
}

.sm-input {
  width: $sm-width;

  /deep/ .el-input__inner {
    border: 0px !important;
  }
}

.el-form-item {
  background-color: white;

  padding-top: 5px;
  padding-bottom: 5px;
}

.vali-code-input {
  width: 110px;

  /deep/ .el-input__inner {
    border: 0px !important;
  }
}

.send-button {
  margin-left: 10px;
  width: 112px;
}

.avatar-uploader {
  position: absolute;
  right: 20px;
  width: 44%;
  top: 30px;
  height: 105px;

  /deep/ .el-upload {
    // border: 1px dashed #d9d9d9 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  /deep/ .el-upload-dragger {
    width: 100%;
    border: 0px;
    height: 105px;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #dbdadb;

  margin: 28px auto;
  margin-bottom: 0px;
}

.avatar {
  width: 100%;
  height: 105px;
  display: block;
}

.sm-form {
  padding-top: 10px;
  //display: none;
}

.top-div {
  height: 40vh;
  background-image: url('../assets/sm-top.jpg');
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  padding-top: 20px;
}

.sm-status {
  font-size: medium;
  margin: 50px auto;
  color: #0083ff;
  background-color: white;
  width: 130px;
  height: 38px;
  line-height: 38px;
  border-radius: 20px;
}

.sfz-div {
  border: 1px dashed #deddde;
  width: 96%;
  height: 150px;
  margin: 10px auto;
  position: relative;
  background-color: white;
  //display: none;
  .sfz-sl {
    margin-top: 30px;
    position: absolute;
    left: 10px;
    width: 44%;
    height: 105px;
  }
}

.sfz-font {
  // position: absolute;
  // top:108px;
  color: #dbdadb;
  font-size: small;

}

.sm-title {
  color: white;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #78a8f2;
}

.title-left {
  position: absolute;

  text-align: left;
  width: 100px;
}

.remark {
  text-align: left;
  padding-right: 12px;
  word-wrap: break-word;
  word-break: normal;
  padding-left: 18px;
  font-size: small;
  padding-bottom: 12px;
  color: red
}
</style>
<script setup>
</script>