import CryptoJS from 'crypto-js'

// export const encryptDes = (message, key) => {
//   var keyHex = CryptoJS.enc.Utf8.parse(key);
//   var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7
//   });
//   return encrypted.toString();
// }

// des加密（有密文）
export const encryptDes = (message, key) => {
  var keyHex = CryptoJS.enc.Utf8.parse(key)
  var ivHex = CryptoJS.enc.Utf8.parse(key)
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}




// 解密
export const decryptDes = (ciphertext, key) => {
  var keyHex = CryptoJS.enc.Utf8.parse(key)
  const decrypted = CryptoJS.DES.decrypt(ciphertext, keyHex, {
    iv: keyHex,
    mode: CryptoJS.mode.CBC, // 可省略
    padding: CryptoJS.pad.Pkcs7 // 可省略
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}
