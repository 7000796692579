<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;

  text-align: center;
  color: #2c3e50;
  height: 100%;
}
  body{
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  html{
    height: 100%;
    box-sizing: border-box;
  }
</style>
