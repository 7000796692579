var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "out-div" },
      [
        _vm.outShow
          ? _c("div", { staticClass: "sm-title" }, [_vm._m(0)])
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "sm-form",
            attrs: { model: _vm.ruleForm, "label-width": "100px" },
          },
          [
            _vm.dictStatus == "C_REFUSE" && _vm.outShow
              ? _c(
                  "div",
                  { staticClass: "sfz-div", staticStyle: { height: "auto" } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { label: "实名状态：", prop: "name" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "sm-input",
                          staticStyle: { color: "red !important" },
                          model: {
                            value: _vm.dictStatusName,
                            callback: function ($$v) {
                              _vm.dictStatusName = $$v
                            },
                            expression: "dictStatusName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "remark" }, [
                      _vm._v(" *" + _vm._s(_vm.remark) + " "),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.IDENTITY_PIC_FRONT
              ? _c(
                  "div",
                  { staticClass: "sfz-div" },
                  [
                    _c("img", {
                      staticClass: "sfz-sl",
                      attrs: { src: require("@/assets/sfz_zm.png") },
                    }),
                    _c(
                      "el-upload",
                      {
                        ref: "file",
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: _vm.upload_qiniu_url,
                          "show-file-list": false,
                          data: _vm.qiniuData,
                          "on-success": _vm.handleAvatarSuccess,
                          "on-error": _vm.handleError,
                          "before-upload": _vm.beforeFrontUpload,
                          accept: "image/*",
                          drag: "",
                        },
                        on: {
                          change: function ($event) {
                            _vm.uptype = 1
                          },
                        },
                      },
                      [
                        _vm.frontImageUrl != ""
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.frontImageUrl },
                            })
                          : _vm._e(),
                        _vm.frontImageUrl == ""
                          ? _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            })
                          : _vm._e(),
                        _vm.frontImageUrl == ""
                          ? _c("div", { staticClass: "sfz-font" }, [
                              _vm._v("请上传身份证正面带姓名面"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.IDENTITY_PIC_BACK
              ? _c(
                  "div",
                  { staticClass: "sfz-div" },
                  [
                    _c("img", {
                      staticClass: "sfz-sl",
                      attrs: { src: require("@/assets/sfz_fm.png") },
                    }),
                    _c(
                      "el-upload",
                      {
                        ref: "file",
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: _vm.upload_qiniu_url,
                          "show-file-list": false,
                          data: _vm.qiniuData,
                          "on-success": _vm.handleAvatarSuccess,
                          "on-error": _vm.handleError,
                          "before-upload": _vm.beforeBackUpload,
                          accept: "image/*",
                          drag: "",
                        },
                        on: {
                          change: function ($event) {
                            _vm.uptype = 2
                          },
                        },
                      },
                      [
                        _vm.backImageUrl != ""
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.backImageUrl },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                        _vm.backImageUrl == ""
                          ? _c("div", { staticClass: "sfz-font" }, [
                              _vm._v("请上传身份证反面带国徽面"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.IDENTITY_PIC_IN_HAND
              ? _c(
                  "div",
                  { staticClass: "sfz-div" },
                  [
                    _c("img", {
                      staticClass: "sfz-sl",
                      staticStyle: { width: "25%", "margin-left": "20px" },
                      attrs: { src: require("@/assets/sfz_shouchi.png") },
                    }),
                    _c(
                      "el-upload",
                      {
                        ref: "file",
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: _vm.upload_qiniu_url,
                          "show-file-list": false,
                          data: _vm.qiniuData,
                          "on-success": _vm.handleAvatarSuccess,
                          "on-error": _vm.handleError,
                          "before-upload": _vm.beforeHandUpload,
                          accept: "image/*",
                          drag: "",
                        },
                        on: {
                          change: function ($event) {
                            _vm.uptype = 3
                          },
                        },
                      },
                      [
                        _vm.handImageUrl != ""
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.handImageUrl },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                        _c("div", { staticClass: "sfz-font" }, [
                          _vm._v("请上传手持正面身份证"),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.outShow
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0px" },
                    attrs: { label: "业务号码：", prop: "name" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "sm-input",
                      model: {
                        value: _vm.cardNumber,
                        callback: function ($$v) {
                          _vm.cardNumber = $$v
                        },
                        expression: "cardNumber",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.CUSTOMER_NAME
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0px" },
                    attrs: { label: "真实姓名：", prop: "name" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "sm-input",
                      attrs: { placeholder: "请输入真实姓名" },
                      model: {
                        value: _vm.form.customerName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerName", $$v)
                        },
                        expression: "form.customerName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.ID_WORDS
              ? _c(
                  "el-form-item",
                  { attrs: { label: "身份证号：", prop: "name" } },
                  [
                    _c("el-input", {
                      staticClass: "sm-input",
                      attrs: { placeholder: "请输入18位二代身份证号" },
                      model: {
                        value: _vm.form.idWords,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "idWords", $$v)
                        },
                        expression: "form.idWords",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.PHONE_NUMBER
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0px" },
                    attrs: { label: "手机号：", prop: "name" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "sm-input",
                      attrs: { placeholder: "请输入11位手机号" },
                      model: {
                        value: _vm.form.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configJson.PHONE_NUMBER
              ? _c(
                  "el-form-item",
                  { attrs: { label: "验证码：", prop: "name" } },
                  [
                    _c("el-input", {
                      staticClass: "vali-code-input",
                      attrs: { placeholder: "6位验证码" },
                      model: {
                        value: _vm.form.msgCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "msgCode", $$v)
                        },
                        expression: "form.msgCode",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "send-button",
                        attrs: {
                          type: "primary",
                          loading: _vm.sendCodeLoading,
                        },
                        on: { click: _vm.sendButtonClick },
                      },
                      [_vm._v(" " + _vm._s(_vm.sendButtonContent) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.displayButton
              ? _c(
                  "el-button",
                  {
                    staticStyle: { width: "80%" },
                    attrs: { type: "primary" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("确定 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title-left" }, [
      _c("i", {
        staticClass: "el-icon-arrow-left",
        staticStyle: { "margin-left": "10px" },
      }),
      _vm._v(" 实名认证 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }