var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("img", {
        staticStyle: { "margin-top": "60px" },
        attrs: {
          src: require("@/assets/dsh.jpg"),
          width: "174",
          height: "133",
        },
      }),
      _c(
        "div",
        { staticStyle: { "font-size": "large", "font-weight": "bold" } },
        [_vm._v("正在审核中")]
      ),
      _c("div", { staticClass: "bottom" }, [
        _vm._v(" 发起申请后，我们会在1-7个工作日进行审核。"),
        _c("br"),
        _vm._v(" 如果有绑定手机号我们会进行短信通知。"),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.display) + " "),
      ]),
      _c(
        "el-button",
        {
          staticClass: "backButton",
          attrs: { type: "primary", round: "", plain: "" },
          on: { click: _vm.back },
        },
        [_vm._v("返回")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }