<template>
    <div style="background-color: #f8f8f8;height: 100vh">
        <img src="@/assets/sm_logo.png" style="width: 80%;"/>
        <el-input v-model="cardNumber" placeholder="请输入卡号/20位ICCID" style="width: 80%;"></el-input>
        <el-button type="primary" style="width: 80%;margin-top: 20px" @click="gotoRealNamePage">开始实名</el-button>
    </div>
</template>

<script>
    import {
        validataRealnameCard
    } from '@/api/getData'

    export default {
        data() {
            return {
                cardNumber: ''
            }
        },
        name: "enter",
        created() {

            localStorage.setItem("reffer", this.$route.query._redir)
            let cardNumber = this.$route.query.cardNumber
            if (cardNumber != undefined) {
                this.cardNumber = cardNumber
            }


        },
        methods: {
            async gotoRealNamePage() {

                if (this.cardNumber == '') {
                    this.$message.warning("请输入卡号或者ICCID")
                    return
                }
                if (this.cardNumber.length <= 3) {
                    this.$message.warning("卡号或者ICCID长度必须大于3")
                    return
                }

                let back = await validataRealnameCard({cardNumber: this.cardNumber})
                if (back._result) {
                    //如果是认证成功
                    if (back.data.dictStatus == "C_THROUGH"||back.data.dictStatus == "AUTOMATIC_PASS") {
                        this.$router.push({path: "/success"})
                    }//如果是正在审核
                    else if (back.data.dictStatus == "C_WAIT") {
                        this.$router.push({path: "/dsh"})
                    }
                    //如果为空
                    if (back.data.dictStatus == null||back.data.dictStatus == 'C_REFUSE') {
                        this.$router.push({path: "/sm", query: {cardNumber: this.cardNumber, cardId: back.data.cardId}})

                    }

                }
            }
        }
    }
</script>

<style scoped>

</style>
