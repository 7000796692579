import scopeVue from './defaultVue'

//  统一封装判断errorCode的方法  传入返回的数据  和 弹框方法
export const errorCode = data => {

  let front = (data.code+"").substr(0,1);
	// 成功返回  数据正常
	if(data.code == 10000){
		data._result = true;
		//console.log(data.data);
		return data
	// 没有认证  或没有权限 或报错
	}else if(data.code != 10000){
		//  判断返回信息字段是否是message

    if(front==3){
			scopeVue.messageFunction(data.msg);
			//  判断返回信息字段是否是msg
		}
		data._result = false;
		return data
	}else{
		// 没有errorCode 数据有误
		//scopeVue.messageFunction('数据未知错误！');
	}
}
