var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#f8f8f8", height: "100vh" } },
    [
      _c("img", {
        staticStyle: { width: "80%" },
        attrs: { src: require("@/assets/sm_logo.png") },
      }),
      _c("el-input", {
        staticStyle: { width: "80%" },
        attrs: { placeholder: "请输入卡号/20位ICCID" },
        model: {
          value: _vm.cardNumber,
          callback: function ($$v) {
            _vm.cardNumber = $$v
          },
          expression: "cardNumber",
        },
      }),
      _c(
        "el-button",
        {
          staticStyle: { width: "80%", "margin-top": "20px" },
          attrs: { type: "primary" },
          on: { click: _vm.gotoRealNamePage },
        },
        [_vm._v("开始实名")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }