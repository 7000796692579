import fetch from '@/config/fetch'

/**
 * Created by Administrator on 2017/9/19.
 */

// 获取alpha key
// export const getAlphaKey = (data, loadingObj) => fetch('/alpha/get', data, loadingObj, 'power-send-check');

export const getUserData = (data, loadingObj) => fetch('/user/list', data, loadingObj, 'permission') // 获取好友推荐列表

export const uploadRealNameExaminePicture = (data, loadingObj) => fetch('/realname-examine/uploadRealNameExaminePicture/white', data, loadingObj, 'crm-system') // 获取OEM信息
export const idCardTest = (data, loadingObj) => fetch('/baidu/idCardTest/white', data, loadingObj, 'open-ability-system') // 获取OEM信息

export const initializationRegister = (data, loadingObj) => fetch('/msg/pageKey.dnl', data, loadingObj, 'oceanside-send-and-check') // 初始化注册页

export const sendVerificationCode = (data, loadingObj) => fetch('/msg/send/identifyCode', data, loadingObj, 'oceanside-send-and-check') // 发送验证码
export const saveRealname = (data, loadingObj) => fetch('/realname-examine/save/white/phoneCheck', data, loadingObj, 'crm-system') // 发送验证码
export const initializeRealName = (data, loadingObj) => fetch('/realname-examine/initializeRealName/white', data, loadingObj, 'crm-system') // 发送验证码
export const validataRealnameCard = (data, loadingObj) => fetch('/realname-examine/validataRealnameCard/white', data, loadingObj, 'crm-system') // 发送验证码
export const unicomInit = (data, loadingObj) => fetch('/payment/unicomInit/white', data, loadingObj, 'OEM-setting-system') // 发送验证码
