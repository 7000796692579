var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unicom-small" },
    [
      _c("img", {
        staticStyle: { width: "80%" },
        attrs: { src: require("@/assets/sm_logo.png") },
      }),
      _c(
        "wx-open-launch-weapp",
        {
          attrs: {
            id: "launch-btn",
            username: "gh_5eac63f4d504",
            patch: _vm.path,
          },
        },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("点击进行官方实名"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }