import {baseUrl} from './env'
import scopeVue from './defaultVue'
import {errorCode} from './errorCode'


//前端统一封装请求后台的方法
export default async (url = '', data = {}, loadingObj = {
    obj: {},
    key: ''
}, sysName = '', type = 'json', method = 'fetch') => {

    let response; // 后台返回数据
    let responseResult;// 定义返回json形式
    let responseJson; // errorCode方法返回

    // 表格 按钮 下拉 loading 改变状态
    loadingObj.key.split(';').map(item => {
        loadingObj.obj[item] = true;
    })
    //

    let newData = null;
    if (type == 'upload') {
        //type="multipart/form-data"
        newData = new FormData();
        for (let a in data) {
            newData.append(a, data[a]);
        }
    } else {

        newData = JSON.stringify(data)
    }

    if (window.fetch && method == 'fetch') {
        let reqHeaders = null;
        if (type != 'upload') {
            reqHeaders = new Headers({
                'Content-Type': 'application/json;charset=UTF-8',
            })
        }

        let requestConfig = {
            credentials: 'include', //传递cookie
            method: 'POST',
            headers: reqHeaders,
        }
        //post请求是参数设置
        Object.defineProperty(requestConfig, 'body', {
            value: newData
        });
        try {
            //设置后台请求地址，加配置
            response = await fetch(baseUrl + '/' + sysName + url, requestConfig);

            // 请求状态码为200
            if (response.status == 200) {
                try {
                    //返回json格式数据
                    responseResult = await response.json();
                    // 判断errorCode  会出现errorCode没有返回值的情况  进入catch
                    responseJson = errorCode(responseResult);

                    // 此处可加入特殊对errorCode的处理

                    /*	if(responseJson.code == 30003) {
                            scopeVue.toLogin(router);
                            //登出时清空localStorage

                            loadingObj.key.split(';').map(item => {
                                loadingObj.obj[item] = false;
                            })
                            // 不让前台报错
                            return false
                        }else{
                            if(token != undefined || token != '' || token != null){
                                setStore("token",responseJson.token);// 更新token
                            }
                //console.log(loadingObj.key)

                            loadingObj.key.split(';').map(item => {
                                loadingObj.obj[item] = false;
                            })
                //console.log(responseJson)
                            return responseJson
                        }*/
                    return responseJson
                } catch (error) {
                    //console.log(error);
                    //  没有返回数据  和 转成不了 json 格式
                    if (responseResult == null || responseResult == undefined) {
                        scopeVue.messageFunction("系统异常，获取数据失败");
                        // errorCode文件方法没有返回值
                    } else if (responseJson == null || responseJson == undefined) {
                        scopeVue.messageFunction("服务器异常，数据有误");
                    } else {
                        scopeVue.messageFunction("系统异常，获取数据有误");
                    }

                    // 不让前台页面报错
                    loadingObj.key.split(';').map(item => {
                        loadingObj.obj[item] = false;
                    })
                    return false
                }
            } else {
                // 可以判断请求状态码  这里统一处理成  服务器异常
                // scopeVue.messageFunction('服务器异常！');

                // 判断请求状态码
                switch (response.status) {
                    case 400:
                        scopeVue.messageFunction('错误请求');
                        break;
                    case 403:
                        scopeVue.messageFunction('请求不被允许');
                        break;
                    case 404:
                        scopeVue.messageFunction('没有发现查询，文件或URL');
                        break;
                    case 500:
                        scopeVue.messageFunction('服务器产生内部错误');
                        break;
                    case 501:
                        scopeVue.messageFunction('服务器不支持请求的函数');
                        break;
                    case 502:
                        scopeVue.messageFunction('服务器暂时不可用');
                        break;
                    case 503:
                        scopeVue.messageFunction('服务器暂时过载或暂时维修');
                        break;
                    case 504:
                        scopeVue.messageFunction('网络连接超时');
                        break;
                    case 505:
                        scopeVue.messageFunction('服务器不支持或拒绝请求头中指定的HTTP版本');
                        break;
                    default:
                        scopeVue.messageFunction('未知错误');
                        break;
                }

                // 不让前台报错
                loadingObj.key.split(';').map(item => {
                    loadingObj.obj[item] = false;
                })
                return false
            }
        } catch (error) {
            // 处理response  目前还没有想到会出现什么问题  暂时写成打印
            //console.log(response);

            // 不让前台报错
            loadingObj.key.split(';').map(item => {
                loadingObj.obj[item] = false;
            })
            return false
        }
    } else {
        return new Promise((resolve, reject) => {
            let requestObj;
            if (window.XMLHttpRequest) {
                requestObj = new XMLHttpRequest();
            } else {
                requestObj = new ActiveXObject;
            }
            //post请求设置，请求参数数据


            requestObj.open('POST', baseUrl + '/' + sysName + url, true);
            requestObj.withCredentials = true;

            if (type != 'upload') {
                requestObj.setRequestHeader("Content-type", 'application/json;charset=UTF-8');
            }

            requestObj.setRequestHeader("token", token);
            requestObj.send(newData);

            requestObj.onreadystatechange = () => {
                if (requestObj.readyState == 4) {
                    if (requestObj.status == 200) {
                        let obj = requestObj.response;
                        if (typeof obj !== 'object') {
                            obj = JSON.parse(obj);
                        }
                        responseJson = errorCode(obj);

                        // 此处可加入特殊对errorCode的处理
                        /*
                                            if(responseJson.code == 10002) {
                                                scopeVue.toLogin(router);
                                                //登出时清空localStorage
                                                loadingObj.key.split(';').map(item => {
                                                    loadingObj.obj[item] = false;
                                                })
                                                // 不让前台报错
                                                return false
                                            }else{
                                                if(token != undefined || token != '' || token != null){
                                                    setStore("token",responseJson.token);// 更新token
                                                }

                                                loadingObj.key.split(';').map(item => {
                                                    loadingObj.obj[item] = false;
                                                })
                                                // setStore("token",obj.token);//更新token
                                                resolve(responseJson); //完成时返回
                                            }*/
                        resolve(responseJson);
                    } else {
                        // reject(requestObj); //失败时返回
                        switch (requestObj.status) {
                            case 400:
                                scopeVue.messageFunction('错误请求');
                                break;
                            case 403:
                                scopeVue.messageFunction('请求不被允许');
                                break;
                            case 404:
                                scopeVue.messageFunction('没有发现查询，文件或URL');
                                break;
                            case 500:
                                scopeVue.messageFunction('服务器产生内部错误');
                                break;
                            case 501:
                                scopeVue.messageFunction('服务器不支持请求的函数');
                                break;
                            case 502:
                                scopeVue.messageFunction('服务器暂时不可用');
                                break;
                            case 503:
                                scopeVue.messageFunction('服务器暂时过载或暂时维修');
                                break;
                            case 504:
                                scopeVue.messageFunction('网络连接超时');
                                break;
                            case 505:
                                scopeVue.messageFunction('服务器不支持或拒绝请求头中指定的HTTP版本');
                                break;
                            default:
                                scopeVue.messageFunction('未知错误');
                                break;
                        }
                        // 不让前台报错
                        loadingObj.key.split(';').map(item => {
                            loadingObj.obj[item] = false;
                        })
                        return false
                    }
                }
            }
        }).catch((err) => {
            //console.log(err)
            // 不让前台报错
            loadingObj.key.split(';').map(item => {
                loadingObj.obj[item] = false;
            })
            return false
        })
    }
}
