<template>
    <div>
        <img src="@/assets/dsh.jpg" width="174" height="133" style="margin-top: 60px">
        <div style="font-size: large;font-weight: bold">正在审核中</div>
        <div class="bottom">
            发起申请后，我们会在1-7个工作日进行审核。<br/>
            如果有绑定手机号我们会进行短信通知。<br/>
            {{display}}
        </div>
        <el-button @click="back" type="primary" round plain class="backButton">返回</el-button>
    </div>
</template>

<script>
    export default {
        name: "success",
        data(){
          return{
              display:"3秒后跳转到上一页"
          }
        },
        created(){
            if(localStorage.getItem("reffer")!=undefined||localStorage.getItem("reffer")!=null){
                this.display="3秒后跳转到上一页"
                setTimeout(()=>{
                    location.href = localStorage.getItem("reffer")
                },3000)
            }
        },
        methods:{
            back(){
                if(localStorage.getItem("reffer")!=undefined||localStorage.getItem("reffer")!=null){
                    location.href = localStorage.getItem("reffer")
                }else{
                    this.$router.push({path: "/"})
                }

            }
        }
    }
</script>

<style scoped>
    .bottom {
        border-radius: 5px;
        background-color: #eff8ff;
        width: 86%;
        margin: 100px auto;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 35px;
        color:#239efe;
        text-align: left;
    }
    .backButton{
        width: 40%;
    }
</style>