<template>
    <div>
        <div class="top"><i class="el-icon-success success-icon" ></i>  <div style="margin-left: 10px">恭喜您!</div></div>
        <div>您的卡号实名认证成功。</div>
        <div>请继续其他操作！</div>
        <el-button @click="back" type="primary" round plain class="backButton">返回</el-button>
    </div>
</template>

<script>
    export default {
        name: "success",
        methods:{
            back(){
                if(localStorage.getItem("reffer")!=undefined||localStorage.getItem("reffer")!=null){
                    location.href = localStorage.getItem("reffer")
                }else{
                    this.$router.push({path: "/"})
                }

            }
        }
    }
</script>

<style scoped>
    .top{
        font-size: 28px;
        font-weight: bold;

        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 90px;
    }
    .success-icon{
        color: #45a940 ;
        font-size: 48px;

    }
    .backButton{ margin-top: 90px;

        width: 40%;
    }
</style>