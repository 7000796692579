<template>
      <div class="unicom-small">
          <img src="@/assets/sm_logo.png" style="width: 80%;"/>

          <wx-open-launch-weapp id="launch-btn" username="gh_5eac63f4d504"  :patch="path">
              <el-button type="primary">点击进行官方实名</el-button>
          </wx-open-launch-weapp>
      </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import {
    unicomInit
} from '@/api/getData'
export default {
    name: "unicom",
    data(){
       return{
           path:''
       }
    },
    async created() {
        let cardNumber = this.$route.query.cardNumber
        if (cardNumber == undefined || cardNumber == '' || cardNumber.length <= 3) {
            this.$router.push({path: "/"})
        }
        // var url = window.location.origin+"?cardNumber="+cardNumber;
          var url = location.href.split('#')[0];
        this.path = "pages/login/index?iccid="+cardNumber
        let res = await unicomInit({url:url});
        wx.config({
            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，填任意非空字符串即可
            signature: res.data.signature, // 必填，填任意非空字符串即可
            jsApiList: ['showOptionMenu', 'chooseImage', 'previewImage'], // 必填，随意一个接口即可
            openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
        })
      /*  wx.ready(function () {
            var btn = document.getElementById('launch-btn');
            btn.addEventListener('launch', function (e) {
                // console.log('success');
            });
            btn.addEventListener('error', function (e) {
                // console.log('fail', e.detail);
            });
        })*/
    }

}
</script>

<style scoped>

</style>